@import (less) "mixins.less";




@media only screen 
and (max-width : 900px) {
    
    }
    
@media only screen 
and (max-width : 800px) {
   a#menu-toggle{
       display:inline-block;
       }
    #menu-main-navigation-1{display:none;}
   
    }
 
@media only screen 
and (max-width : 768px) {
    .mobile-center{.centered();}
    .mobile-hide{display:none!important;}
    }
   
@media only screen 
and (max-width : 480px) {



}